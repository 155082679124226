var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header-mobile align-items-center",
      class: _vm.headerClasses,
      attrs: { id: "kt_header_mobile" }
    },
    [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          staticClass: "logo-default max-h-30px",
          attrs: { alt: "Logo", src: _vm.headerLogo }
        })
      ]),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center" }, [
      _c(
        "button",
        {
          staticClass: "btn p-0 burger-icon burger-icon-left",
          attrs: { id: "kt_aside_mobile_toggle" }
        },
        [_c("span")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }