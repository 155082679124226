var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "menu-nav p-0" },
    [
      _c("router-link", {
        attrs: { to: "/users/" + _vm.myProfileId },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var href = ref.href
              var navigate = ref.navigate
              var isActive = ref.isActive
              var isExactActive = ref.isExactActive
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: [
                      isActive && "menu-item-active",
                      isExactActive && "menu-item-active"
                    ],
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover"
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate }
                      },
                      [
                        _c("i", { staticClass: "menu-icon far fa-user" }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Mi perfil")
                        ])
                      ]
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("router-link", {
        attrs: { to: "/change-password" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var href = ref.href
              var navigate = ref.navigate
              var isActive = ref.isActive
              var isExactActive = ref.isExactActive
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: [
                      isActive && "menu-item-active",
                      isExactActive && "menu-item-active"
                    ],
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover"
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate }
                      },
                      [
                        _c("i", { staticClass: "menu-icon fas fa-key" }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Cambiar Contraseña")
                        ])
                      ]
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "li",
        {
          staticClass: "menu-item",
          attrs: { "aria-haspopup": "true", "data-menu-toggle": "hover" }
        },
        [
          _c(
            "button",
            { staticClass: "menu-link", on: { click: _vm.onLogout } },
            [
              _c("i", { staticClass: "menu-icon mdi mdi-logout-variant" }),
              _c("span", { staticClass: "menu-text" }, [
                _vm._v("Cerrar Sesión")
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }