<template>
  <ul class="menu-nav p-0">
    <router-link
      :to="`/users/${myProfileId}`"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-user"></i>
          <span class="menu-text">Mi perfil</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/change-password"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-key"></i>
          <span class="menu-text">Cambiar Contraseña</span>
        </a>
      </li>
    </router-link>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
      <button class="menu-link" @click="onLogout">
        <i class="menu-icon mdi mdi-logout-variant"></i>
        <span class="menu-text">Cerrar Sesión</span>
      </button>
    </li>
  </ul>
</template>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "KTMenuBottom",
  data() {
    return {
      myProfileId: ""
    };
  },
  async mounted() {
    this.getResource();
  },
  methods: {
    async getResource() {
      let { data } = await ApiService.get(`admins`, "me");
      console.log(data);
      this.myProfileId = data.id;
      return data;
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  }
};
</script>
