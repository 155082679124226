var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "menu-nav" },
    [
      _vm._m(0),
      _vm._m(1),
      _vm.canAccess(["ADMIN_GLOBAL", "ADMIN_AI_PRODUCTS"])
        ? _c("router-link", {
            attrs: { to: "/services" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          staticClass: "menu-item",
                          class: [
                            isActive && "menu-item-active",
                            isExactActive && "menu-item-active"
                          ],
                          attrs: {
                            "aria-haspopup": "true",
                            "data-menu-toggle": "hover"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "menu-link",
                              attrs: { href: href },
                              on: { click: navigate }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "menu-icon far fas fa-shopping-basket"
                              }),
                              _c("span", { staticClass: "menu-text" }, [
                                _vm._v("Servicios")
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              846426228
            )
          })
        : _vm._e(),
      _vm.canAccess(["ADMIN_GLOBAL", "ADMIN_TENANTS"])
        ? _c("router-link", {
            attrs: { to: "/clients" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          staticClass: "menu-item",
                          class: [
                            isActive && "menu-item-active",
                            isExactActive && "menu-item-active"
                          ],
                          attrs: {
                            "aria-haspopup": "true",
                            "data-menu-toggle": "hover"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "menu-link",
                              attrs: { href: href },
                              on: { click: navigate }
                            },
                            [
                              _c("i", { staticClass: "menu-icon far fa-user" }),
                              _c("span", { staticClass: "menu-text" }, [
                                _vm._v("Clientes")
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1843576960
            )
          })
        : _vm._e(),
      _vm.canAccess(["ADMIN_GLOBAL", "ADMIN_USERS"])
        ? _c("router-link", {
            attrs: { to: "/users" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          staticClass: "menu-item",
                          class: [isExactActive && "menu-item-active"],
                          attrs: {
                            "aria-haspopup": "true",
                            "data-menu-toggle": "hover"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "menu-link",
                              attrs: { href: href },
                              on: { click: navigate }
                            },
                            [
                              _c("i", {
                                staticClass: "menu-icon far fa-user-circle"
                              }),
                              _c("span", { staticClass: "menu-text" }, [
                                _vm._v("Administradores")
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1520704333
            )
          })
        : _vm._e(),
      _vm._m(2),
      _vm.canAccess(["ADMIN_GLOBAL", "ADMIN_TENANT_MESSAGES"])
        ? _c("router-link", {
            attrs: { to: "/notifications" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          staticClass: "menu-item",
                          class: [isExactActive && "menu-item-active"],
                          attrs: {
                            "aria-haspopup": "true",
                            "data-menu-toggle": "hover"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "menu-link",
                              attrs: { href: href },
                              on: { click: navigate }
                            },
                            [
                              _c("i", {
                                staticClass: "menu-icon fab fa-rocketchat"
                              }),
                              _c("span", { staticClass: "menu-text" }, [
                                _vm._v(_vm._s(_vm.$t("MENU.NOTIFICATIONS")))
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              119141291
            )
          })
        : _vm._e(),
      _vm.canAccess(["ADMIN_GLOBAL", "ADMIN_REQUEST_VALIDATIONS"])
        ? _c("router-link", {
            attrs: { to: "/validations" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          staticClass: "menu-item",
                          class: [isExactActive && "menu-item-active"],
                          attrs: {
                            "aria-haspopup": "true",
                            "data-menu-toggle": "hover"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "menu-link",
                              attrs: { href: href },
                              on: { click: navigate }
                            },
                            [
                              _c("i", { staticClass: "menu-icon fa fa-check" }),
                              _c("span", { staticClass: "menu-text" }, [
                                _vm._v(_vm._s(_vm.$t("MENU.VALIDATIONS")))
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              4058333747
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "w-100 d-flex justify-content-center" }, [
      _c("figure", { staticClass: "w-50 mb-8" }, [
        _c("a", { attrs: { href: "/#/services" } }, [
          _c("img", {
            staticClass: "w-100",
            attrs: { src: "media/lexicai/logos/logo-lexic.png", alt: "" }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "menu-section" }, [
      _c("h4", { staticClass: "menu-text" }, [_vm._v("Administración")]),
      _c("i", { staticClass: "menu-icon flaticon-more-v2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "menu-section" }, [
      _c("h4", { staticClass: "menu-text" }, [_vm._v("Herramientas")]),
      _c("i", { staticClass: "menu-icon flaticon-more-v2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }