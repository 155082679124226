var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.right",
          value: "Perfil de usuario",
          expression: "'Perfil de usuario'",
          modifiers: { hover: true, right: true }
        }
      ]
    },
    [
      _c(
        "a",
        {
          staticClass: "btn btn-icon btn-clean btn-lg w-40px h-40px",
          attrs: {
            href: "#",
            id: "kt_quick_user_toggle",
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-container": "body",
            "data-boundary": "window",
            title: "",
            "data-original-title": "Perfil de usuario"
          }
        },
        [
          _c("span", { staticClass: "symbol symbol-30 symbol-lg-40" }, [
            _c(
              "span",
              { staticClass: "svg-icon svg-icon-xl" },
              [
                _c("inline-svg", {
                  attrs: { src: "media/svg/icons/General/User.svg" }
                })
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          ref: "kt_quick_user",
          staticClass: "offcanvas offcanvas-left p-10",
          attrs: { id: "kt_quick_user" }
        },
        [
          _vm._m(0),
          _c(
            "perfect-scrollbar",
            {
              staticClass: "offcanvas-content pr-5 mr-n5 scroll",
              staticStyle: { "max-height": "90vh", position: "relative" }
            },
            [
              _c("div", { staticClass: "d-flex align-items-center mt-5" }, [
                _c("div", { staticClass: "symbol symbol-100 mr-5" }, [
                  _c("img", {
                    staticClass: "symbol-label",
                    attrs: { src: _vm.default_photo, alt: "" }
                  }),
                  _c("i", { staticClass: "symbol-badge bg-success" })
                ]),
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "font-weight-bold font-size-h5 text-dark-75 text-hover-primary",
                      attrs: { href: "#" }
                    },
                    [_vm._v(" " + _vm._s(_vm.currentUser.name) + " ")]
                  ),
                  _c("div", { staticClass: "text-muted mt-1" }, [
                    _vm._v("Administrador")
                  ]),
                  _c("div", { staticClass: "navi mt-2" }, [
                    _c(
                      "a",
                      {
                        staticClass: "navi-item",
                        attrs: { href: "mailto:" + _vm.currentUser.email }
                      },
                      [
                        _c("span", { staticClass: "navi-link p-0 pb-2" }, [
                          _c("span", { staticClass: "navi-icon mr-1" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "svg-icon svg-icon-lg svg-icon-primary"
                              },
                              [
                                _c("inline-svg", {
                                  attrs: {
                                    src:
                                      "media/svg/icons/Communication/Mail-notification.svg"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "navi-text text-muted text-hover-primary"
                            },
                            [_vm._v(" " + _vm._s(_vm.currentUser.email) + " ")]
                          )
                        ])
                      ]
                    )
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light-primary btn-bold",
                      on: { click: _vm.onLogout }
                    },
                    [_vm._v(" Cerrar sesión ")]
                  )
                ])
              ]),
              _c("div", {
                staticClass: "separator separator-dashed mt-8 mb-5"
              }),
              _c(
                "div",
                { staticClass: "navi navi-spacer-x-0 p-0" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "navi-item",
                      attrs: { to: "/users/alexhg", href: "#" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.closeOffcanvas($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "navi-link" }, [
                        _c(
                          "div",
                          { staticClass: "symbol symbol-40 bg-light mr-3" },
                          [
                            _c("div", { staticClass: "symbol-label" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "svg-icon svg-icon-md svg-icon-success"
                                },
                                [
                                  _c("inline-svg", {
                                    attrs: {
                                      src:
                                        "media/svg/icons/General/Notification2.svg"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "navi-text" }, [
                          _c("div", { staticClass: "font-weight-bold" }, [
                            _vm._v("Mi Perfil")
                          ]),
                          _c("div", { staticClass: "text-muted" }, [
                            _vm._v(" Ajustes de cuenta y más ")
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "navi-item",
                      attrs: { to: "/change-password", href: "#" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.closeOffcanvas($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "navi-link" }, [
                        _c(
                          "div",
                          { staticClass: "symbol symbol-40 bg-light mr-3" },
                          [
                            _c("div", { staticClass: "symbol-label" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "svg-icon svg-icon-md svg-icon-success"
                                },
                                [
                                  _c("inline-svg", {
                                    attrs: {
                                      src:
                                        "media/svg/icons/Communication/Shield-user.svg"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "navi-text" }, [
                          _c("div", { staticClass: "font-weight-bold" }, [
                            _vm._v("Cambiar contraseña")
                          ]),
                          _c("div", { staticClass: "text-muted" }, [
                            _vm._v(" Cambia la credencial de acceso ")
                          ])
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "separator separator-dashed my-7" })
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "offcanvas-header d-flex align-items-center justify-content-between pb-5"
      },
      [
        _c("h3", { staticClass: "font-weight-bold m-0" }, [
          _vm._v(" Perfil de usuario ")
        ]),
        _c(
          "a",
          {
            staticClass: "btn btn-xs btn-icon btn-light btn-hover-primary",
            attrs: { href: "#", id: "kt_quick_user_close" }
          },
          [_c("i", { staticClass: "ki ki-close icon-xs text-muted" })]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }