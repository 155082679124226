var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12"
    },
    [
      _c("router-link", { attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "max-h-30px",
          attrs: { src: _vm.siteLogo(), alt: "Logo" }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }