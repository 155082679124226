<template>
  <ul class="menu-nav">
    
    <li class="w-100 d-flex justify-content-center">
      <figure class="w-50 mb-8">
        <a href="/#/services">
          <img src="media/lexicai/logos/logo-lexic.png" class="w-100" alt="" />
        </a>
      </figure>
    </li>
    <!-- <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link> -->
    <li class="menu-section">
      <h4 class="menu-text">Administración</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      to="/services"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="canAccess([
        'ADMIN_GLOBAL',
        'ADMIN_AI_PRODUCTS'
      ])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fas fa-shopping-basket"></i>
          <span class="menu-text">Servicios</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/clients"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="canAccess([
        'ADMIN_GLOBAL',
        'ADMIN_TENANTS'
      ])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-user"></i>
          <span class="menu-text">Clientes</span>
        </a>
      </li>
    </router-link>

    <router-link 
      to="/users" 
      v-slot="{ href, navigate, isExactActive }"
      v-if="canAccess([
        'ADMIN_GLOBAL',
        'ADMIN_USERS'
      ])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-user-circle"></i>
          <span class="menu-text">Administradores</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section"><h4 class="menu-text">Herramientas</h4><i class="menu-icon flaticon-more-v2"></i></li>

    <router-link 
      to="/notifications" 
      v-slot="{ href, navigate, isExactActive }"
      v-if="canAccess([
        'ADMIN_GLOBAL',
        'ADMIN_TENANT_MESSAGES'
      ])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fab fa-rocketchat"></i>
          <span class="menu-text">{{ $t("MENU.NOTIFICATIONS") }}</span>
        </a>
      </li>
    </router-link>

    <router-link 
      to="/validations" 
      v-slot="{ href, navigate, isExactActive }"
      v-if="canAccess([
        'ADMIN_GLOBAL',
        'ADMIN_REQUEST_VALIDATIONS'
      ])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-check"></i>
          <span class="menu-text">{{ $t("MENU.VALIDATIONS") }}</span>
        </a>
      </li>
    </router-link>
    
  </ul>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "KTMenu",
  data(){
    return {
      permissions: []
    }
  },
  async mounted(){
    this.permissions = await this.getUserPermissions();
  },
  methods: {
    canAccess(requestedPermissions){
      if(!this.permissions.some(permission => requestedPermissions.includes(permission)))
        return false

      return true;
    },
    async getUserPermissions(){
      let { data } = await ApiService.get(`admins`, "me");
      return data.permissions;
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
