var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "subheader py-3 py-lg-8 subheader-transparent",
      class: _vm.subheaderClasses,
      attrs: { id: "kt_subheader" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap",
          class: {
            "container-fluid": _vm.widthFluid,
            container: !_vm.widthFluid
          }
        },
        [
          _c("div", { staticClass: "d-flex align-items-baseline mr-5" }, [
            _c(
              "h2",
              {
                staticClass:
                  "subheader-title text-dark font-weight-bold my-2 mr-3"
              },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            ),
            _c(
              "ul",
              {
                staticClass:
                  "breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
              },
              [
                _vm._l(_vm.breadcrumbs, function(breadcrumb, i) {
                  return [
                    _c(
                      "li",
                      {
                        key: i + "-" + breadcrumb.id,
                        staticClass: "breadcrumb-item"
                      },
                      [
                        breadcrumb.route
                          ? _c(
                              "router-link",
                              {
                                key: i + "-" + breadcrumb.id,
                                staticClass: "text-muted",
                                attrs: { to: breadcrumb.route }
                              },
                              [_vm._v(" " + _vm._s(breadcrumb.title) + " ")]
                            )
                          : _vm._e(),
                        !breadcrumb.route
                          ? _c(
                              "span",
                              {
                                key: i + "-" + breadcrumb.id,
                                staticClass: "text-muted"
                              },
                              [_vm._v(" " + _vm._s(breadcrumb.title) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                })
              ],
              2
            )
          ]),
          _c("div", { staticClass: "d-flex align-items-center" })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }