var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "kt_aside",
      staticClass: "aside aside-left d-flex aside-fixed",
      attrs: { id: "kt_aside" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "aside-primary d-none flex-column align-items-center flex-row-auto"
        },
        [
          _c("KTBrand"),
          _c(
            "div",
            {
              staticClass:
                "aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps",
              staticStyle: { height: "528px", overflow: "hidden" }
            },
            [
              _c(
                "ul",
                { staticClass: "nav flex-column", attrs: { role: "tablist" } },
                [
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right",
                          value: "Administración",
                          expression: "'Administración'",
                          modifiers: { hover: true, right: true }
                        }
                      ],
                      staticClass: "nav-item mb-3",
                      attrs: {
                        "data-placement": "right",
                        "data-container": "body",
                        "data-boundary": "window"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "nav-link btn btn-icon btn-clean btn-lg active",
                          attrs: { href: "#", role: "tab", "data-tab": "1" },
                          on: { click: _vm.setActiveTab }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-icon svg-icon-xl" },
                            [
                              _c("inline-svg", {
                                attrs: {
                                  src: "media/svg/icons/Home/Library.svg"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10"
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right",
                      value: "Menú lateral",
                      expression: "'Menú lateral'",
                      modifiers: { hover: true, right: true }
                    }
                  ],
                  ref: "kt_aside_toggle",
                  staticClass:
                    "aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm",
                  attrs: {
                    id: "kt_aside_toggle",
                    "data-placement": "right",
                    "data-container": "body",
                    "data-boundary": "window"
                  }
                },
                [_c("i", { staticClass: "ki ki-bold-arrow-back icon-sm" })]
              ),
              _c("KTQuickUser")
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "aside-secondary d-flex flex-row-fluid" }, [
        _c(
          "div",
          {
            staticClass: "aside-workspace scroll scroll-push my-2 ps",
            staticStyle: { height: "824px", overflow: "hidden" }
          },
          [
            _c(
              "b-tabs",
              {
                staticClass: "hide-tabs",
                model: {
                  value: _vm.tabIndex,
                  callback: function($$v) {
                    _vm.tabIndex = $$v
                  },
                  expression: "tabIndex"
                }
              },
              [
                _c("b-tab", { attrs: { active: "" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "aside-menu-wrapper flex-column-fluid pb-5 px-5",
                      attrs: { id: "kt_aside_menu_wrapper" }
                    },
                    [
                      _c(
                        "div",
                        {
                          ref: "kt_aside_menu",
                          staticClass: "aside-menu min-h-lg-90vh",
                          class: _vm.asideMenuClass,
                          attrs: {
                            id: "kt_aside_menu",
                            "data-menu-vertical": "1",
                            "data-menu-scroll": "1"
                          }
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            {
                              staticClass:
                                "aside-menu scroll d-flex flex-column justify-content-between",
                              staticStyle: {
                                "min-height": "93vh",
                                position: "relative"
                              }
                            },
                            [_c("KTMenu"), _c("KTMenuBottom")],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }