var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "footer py-4 d-flex flex-lg-column",
      attrs: { id: "kt_footer" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column flex-md-row align-items-center justify-content-between",
          class: {
            "container-fluid": _vm.widthFluid,
            container: !_vm.widthFluid
          }
        },
        [_vm._m(0), _vm._m(1)]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-dark order-2 order-md-1" }, [
      _c("span", { staticClass: "text-muted font-weight-bold mr-2" }, [
        _vm._v("2021©")
      ]),
      _c("span", { staticClass: "text-dark-75" }, [_vm._v("Lexic.ai")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav nav-dark order-1 order-md-2" }, [
      _c(
        "a",
        {
          staticClass: "nav-link pr-3 pl-0",
          attrs: {
            href: "https://lexic.ai/nuestro-proposito/",
            target: "_blank"
          }
        },
        [_vm._v("About")]
      ),
      _c(
        "a",
        {
          staticClass: "nav-link px-3",
          attrs: { href: "https://lexic.ai/equipo/", target: "_blank" }
        },
        [_vm._v("Team")]
      ),
      _c(
        "a",
        {
          staticClass: "nav-link pl-3 pr-0",
          attrs: {
            href: "https://lexic.ai/servicios/#services_four_section",
            target: "_blank"
          }
        },
        [_vm._v("Contact")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }